<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-11 11:10:04
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-11 15:17:35
-->
<template>
  <div class="upload-content">
    <van-uploader
      v-model="fileList"
      :max-count="10"
      :max-size="maxSizeFile"
      @oversize="onOversize"
      :after-read="afterRead"
      :before-read="beforeRead"
      @delete="deletePic"
    />
  </div>
</template>
<script>
import { Uploader, Toast } from "vant";
import { ref, getCurrentInstance } from "vue";
import { getOssSignature } from "@/api/upload";
export default {
  components: {
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
  },
  props: {
    defaultFileList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const defaultFileLists = ref(props.defaultFileList);
    const { proxy } = getCurrentInstance();
    const fileList = ref([]);
    const currentFileLists = ref([]);
    if (defaultFileLists.value.length) {
      fileList.value = defaultFileLists.value.map((item) => {
        return {
          url: item.url,
          isImage: true,
          name: item.name,
        };
      });
      currentFileLists.value = defaultFileLists.value;
    }
    const afterRead = (file) => {
      file.status = "uploading";
      file.message = "上传中...";
      currentGetOss((res) => {
        const ossData = new FormData();
        ossData.append("OSSAccessKeyId", res.body.accessid);
        ossData.append("policy", res.body.policy);
        ossData.append("Signature", res.body.signature);
        ossData.append("key", "images/studentInfo/" + res.body.fileName);
        ossData.append("file", file.file);
        proxy.$http.post(`${res.body.host}`, ossData).then(() => {
          file.status = "done";
          file.message = "上传成功";
          let imgUrl =
            redirect(res.body.host) + "images/studentInfo/" + res.body.fileName;
          currentFileLists.value.push({
            url: imgUrl,
            name: file.file.name,
          });
          context.emit("getCurrentImgList", currentFileLists);
        });
      });
    };
    const redirect = (oldURL) => {
      let URL = oldURL;
      var loc = location.href.split(":");
      const arr = oldURL.split(":");
      if (loc[0] == "https") {
        URL = "https:" + arr[1];
      } else if (loc[0] == "http") {
        URL = "http:" + arr[1];
      }
      return URL;
    };
    const deletePic = (img) => {
      const currentIndex = currentFileLists.value.findIndex((item) => {
        return item.name === img.file.name;
      });
      currentFileLists.value.splice(currentIndex, 1);
    };
    const beforeRead = () => {
      return true;
    };
    const currentGetOss = (cb) => {
      getOssSignature().then((res) => {
        cb(res);
      });
    };
    const returnCurrentFileList = () => {
      return currentFileLists;
    };
    const onOversize = () => {
      Toast({ message: "上传文件不能超过5M" });
    };
    const maxSizeFile = ref(1024 * 1024 * 5);
    return {
      afterRead,
      beforeRead,
      fileList,
      maxSizeFile,
      deletePic,
      onOversize,
      returnCurrentFileList,
    };
  },
};
</script>
<style lang="less" scoped>
</style>