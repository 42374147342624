<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-13 10:11:40
 * @LastEditors: sueRimn
 * @LastEditTime: 2021-05-13 10:30:33
-->
<template>
  <div class="cell-wrapper">
    <div class="cell-content">
      <div class="title">
        <span v-if="isRequire" class="is-require">*</span>
        {{title}}
      </div>
      <div class="time" @click="openModel">
        <slot>
          <span :class="['message', colors!== '请选择'? 'active': '']">{{message}}</span>
          <van-icon name="arrow" />
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "vant";
import { computed } from "vue";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    title: {
      type: String,
      defualt: "",
    },
    message: {
      type: String,
      defualt: "",
    },
    isRequire: {
      type: Boolean,
      defualt: false,
    },
  },
  setup(props, context) {
    const colors = computed(() => props.message);

    const openModel = () => {
      context.emit("openModel");
    };
    return {
      openModel,
      colors,
    };
  },
};
</script>
<style lang="less" scoped>
.cell-wrapper {
  padding: 40px 0px;
  border-bottom: 1px solid #eee;
  .cell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    color: #333;
    .time {
      display: flex;
      align-items: center;
      span {
        margin-right: 26px;
      }
      .message {
        color: #ccc;
      }
      .active {
        color: #333;
      }
    }
  }
}
</style>