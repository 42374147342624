<!--
 * @Descripttion: 
 * @Author: changh
 * @Date: 2021-05-11 10:08:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-01 10:53:06
-->
<template>
  <div class="follow-up-wrapper">
    <div class="follow-up-content">
      <textarea v-model="contentMessage" class="content-message" placeholder="勤跟进，多签单..."></textarea>
    </div>
    <div class="upload-wrapper">
      <s-upload
        ref="myUpload"
        @getCurrentImgList="getCurrentImgList"
        :defaultFileList="defaultPicList"
      ></s-upload>
    </div>
    <my-cell title="跟进时间" :message=" formatTimeStamp(choseDate)" @openModel="openTime"></my-cell>
    <div class="follow-up-status">
      <div class="title">跟进状态</div>
      <div class="status-lists mt25">
        <my-status-list
          @getStatusList="getStatusList"
          :statusList="statusList"
          :checkedData="defaultChoseStatus"
        ></my-status-list>
      </div>
    </div>
    <van-popup v-model:show="openModel" round position="bottom" :style="{ height: '50%' }">
      <div class="content-model">
        <div class="content-picker">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="confirm"
            @cancel="cancel"
          />
          <!-- <my-picker></my-picker> -->
        </div>
      </div>
    </van-popup>
    <div class="button-wrapper">
      <my-button
        :isShowLeft="false"
        rightText="提交"
        :successSubmitRight="submitStatus"
        @handleButtonRight="handleButtonRight"
        :loadingRight="loading"
      ></my-button>
    </div>
  </div>
</template>
<script>
import sUpload from "@/components/upload";
import myStatusList from "@/components/statusList";
import myButton from "@/components/buttonGroup";
// import myPicker from "@/components/picker";
import { addContact } from "@/api/index";
import { Icon, Popup, DatetimePicker, Toast } from "vant";
import { followUpList } from "@/utils/const";
import { formatTimeStamp } from "@/utils/commUtil";
import { computed, reactive, ref, inject, watch } from "vue";
import { filterTime } from "@/utils/timePicker";
import myCell from "@/components/myCell";
import { useRouter } from "vue-router";
export default {
  name: "Follow",
  components: {
    sUpload,
    myCell,
    myButton,
    myStatusList,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    const router = useRouter();
    const openModel = ref(false);
    const choseDate = ref(new Date());
    const currentDate = ref(new Date());
    const currentChoseTime = ref("请选择");
    const contentMessage = ref("");
    const choseStatus = reactive({});
    const loading = ref(false);
    const picLists = ref([]);
    const myUpload = ref(null);
    const defaultPicList = ref([]);
    const defaultChoseStatus = ref("-1");
    const statusList = ref(followUpList);
    const openTime = () => {
      openModel.value = true;
    };
    const isRetain = inject("isRetain");
    watch(
      isRetain,
      (val) => {
        if (val !== -1) {
          let currentMessage = {
            retainMessage: contentMessage.value || "",
            retainpicLists: picLists.value,
            retainchoseDate: choseDate.value,
            retainchoseStatus: choseStatus.value || "-1",
          };
          sessionStorage.setItem(
            "currentRetainMessage",
            JSON.stringify(currentMessage)
          );
        }
      },
      { deep: true }
    );
    // 保留
    if (sessionStorage.getItem("currentRetainMessage")) {
      const {
        retainMessage,
        retainpicLists,
        retainchoseDate,
        retainchoseStatus,
      } = JSON.parse(sessionStorage.getItem("currentRetainMessage"));
      contentMessage.value = retainMessage || "";
      defaultPicList.value = retainpicLists || [];
      console.log(retainpicLists);
      picLists.value = retainpicLists;
      choseDate.value = new Date(retainchoseDate);
      choseStatus.value = retainchoseStatus || {};
      defaultChoseStatus.value = retainchoseStatus.type || "-1";
    }
    const formatter = (type, value) => {
      if (type === "year") {
        return value + "年";
      }
      if (type === "month") {
        return value + "月";
      }
      if (type === "day") {
        return value + "日";
      }
      return value;
    };
    const confirm = () => {
      choseDate.value = currentDate.value;
      openModel.value = false;
    };
    const cancel = () => {
      openModel.value = false;
    };
    const getCurrentImgList = (urls) => {
      picLists.value = urls.value;
    };
    const getStatusList = (lists) => {
      choseStatus.value = lists;
    };
    const handleButtonRight = () => {
      loading.value = true;
      let content = "";
      let divDom = document.createElement("div");
      let pDom = document.createElement("p");
      pDom.innerText = contentMessage.value;
      picLists.value.forEach((item) => {
        let imgDom = document.createElement("img");
        imgDom.setAttribute("src", item.url);
        pDom.appendChild(imgDom);
      });
      divDom.appendChild(pDom.cloneNode(true));
      content = divDom.innerHTML;
      let customerId = localStorage.getItem("customerId");
      const paramsData = {
        contactTime: choseDate.value.getTime(),
        contactType: 8, // 企业微信
        content: content,
        themId: customerId,
        themeStatus: choseStatus.value.type,
        themeType: 2, // 客户跟进
      };
      addContact(paramsData).then((res) => {
        if (res && res.body) {
          loading.value = false;
          sessionStorage.clear("currentRetainMessage");
          Toast({
            message: "添加成功!",
            duration: 3000,
            onClose: () => {
              router.push({
                path: "/client",
              });
            },
          });
        }
      });
    };
    const submitStatus = computed(() => {
      if (
        (contentMessage.value || picLists.value.length) &&
        currentDate.value &&
        choseStatus.value
      ) {
        return false;
      } else {
        return true;
      }
    });
    return {
      openTime,
      openModel,
      currentDate,
      contentMessage,
      loading,
      filterTime,
      maxDate: new Date(),
      formatter,
      handleButtonRight,
      cancel,
      defaultChoseStatus,
      confirm,
      choseDate,
      formatTimeStamp,
      getStatusList,
      currentChoseTime,
      submitStatus,
      myUpload,
      statusList,
      defaultPicList,
      getCurrentImgList,
      isRetain,
    };
  },
};
</script>
<style lang="less" scoped>
.follow-up-wrapper {
  padding: 32px;
  .follow-up-content {
    .content-message {
      width: 100%;
      height: 200px;
      border: none;
      resize: none;
    }
    // height: 200px;
  }
  .upload-wrapper {
    padding-bottom: 40px;
    border-bottom: 1px solid #eeeeee;
  }
  .follow-up-status {
    margin-top: 40px;
    .title {
      font-size: 30px;
      color: #333;
    }
  }
  .content-model {
    height: 600px;
    .content-picker {
      height: 400px;
    }
  }
  .button-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
  }
}
</style>
